@import "~@flaticon/flaticon-uicons/css/all/all";
@import "~@flaticon/flaticon-uicons/css/uicons-thin-rounded";
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css');

.fixedWindow {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #e5e5e5;
  z-index: 999;
}

header.fixed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  position: fixed;
  top: 0;
  right: 0;
  left: 350px;
  padding: 24px 32px;
}

header button {
  color: #2F2F36;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: all .2s;
}

header button i {
  line-height: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}

header button:hover {
  opacity: .8;
}

.userAvatar {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: 48px;
  width: 48px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-color: #201926;
  color: #fff;
}

.loader {
  display: flex;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: #8b8b8b;
  font-weight: 300;
}

.loader svg {
  color: #201926;
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.googleBtn,
.submitBtn {
  display: inline-block;
  border: none;
  position: relative;
  border-radius: 20px;
  padding: 13px 16px 12px 50px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #221726;
  color: #fff;
  margin-bottom: 8px;
  transition: all .2s ease-in;
}

.submitBtn.bordered {
  background: none;
  border: 2px #221726 solid;
  color: #221726;
  padding: 11px 16px 10px 50px;
}

.submitBtn.bordered svg {
  color: #241426;
}

.googleBtn img,
.submitBtn svg {
  position: absolute;
  left: 16px;
  top: calc(50% - 20px/2);
  width: 20px;
  transition: all .2s ease-in;
}

.submitBtn svg {
  font-size: 20px;
  color: #ffae00;
}

.googleBtn:hover,
.submitBtn:hover {
  background-color: #29212c;
  color: #fff;
}

.submitBtn.bordered:hover svg {
  color: #fff;
}

.pathLink {
  display: inline-block;
  background: none;
  border: none;
  text-decoration: none;
  background-color: #322134;
  color: #fff;
  padding: 13px 16px 12px 50px;
  margin-bottom: 8px;
  position: relative;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .2s ease-in;
}

.pathLink:hover {
  background-color: #4f3e4e;
}

.pathLink:last-of-type {
  margin-bottom: 32px;
}

.pathLink svg {
  position: absolute;
  color: #f7b032;
  left: 16px;
  top: calc(50% - 20px/2);
  width: 20px;
  font-size: 20px;
  transition: all .2s ease-in;
}

.mainSection {
  display: grid;
  grid-template-columns: 350px auto;
  align-items: start;
  column-gap: 0;
  height: 100vh;
  overflow: hidden;
}

select {
  display: inline-block;
  max-width: 100%;
  border-radius: 20px;
  font-size: 14px;
  background: none;
  border: 2px #221726 solid;
  color: #221726;
  padding: 11px 16px 10px 16px;
  outline: none;
  font-weight: 500;
  transition: all .2s ease;
  margin-bottom: 8px;
}

.saveMessage {
  display: inline-block;
  position: fixed;
  right: -200px;
  bottom: 0;
  z-index: 99;
  background-color: #bee7b0;
  color: #319c0e;
  border-radius: 20px 0 0 0;
  padding: 13px 16px 12px 16px;
  font-size: 14px;
  font-weight: 700;
  transition: all .2s ease-in-out;
}

.saveMessage.visible {
  right: 0;
}

.actionBtn {
  display: inline-block;
  position: fixed;
  right: calc(-124px + 50px);
  width: 124px;
  text-align: left;
  bottom: calc(var(--level) * 43px + var(--level) * 8px);
  z-index: 98;
  border: none;
  border-radius: 20px 0 0 20px;
  padding: 13px 16px 12px 50px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .2s ease-in;
}

.actionBtn:last-of-type {
  border-radius: 20px 0 0 0;
}

.actionBtn:hover,
.actionBtn.load,
.actionBtn.error,
.actionBtn.saved {
  right: 0;
}

.actionBtn svg {
  position: absolute;
  color: #ffae00;
  left: 16px;
  top: calc(50% - 20px/2);
  font-size: 20px;
}

.actionBtn {
  background-color: #221726;
  color: #fff;
}

.actionBtn:hover {
  background-color: #29212c;
}

.leftBar {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px 0 32px 32px;
  background-color: #ffffff;
  border-radius: 0px 30px 30px 0px;
  overflow: hidden;
}

.leftBar__head {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  align-items: end;
  padding-right: 32px;
}

.leftBar__logo {
  height: 32px;
}

.leftBar__version {
  text-align: left;
  font-size: 12px;
  color: #000;
  opacity: .5;
  margin-bottom: 4px;
}

.leftBar__flex {
  margin-top: 80px;
  padding-right: 32px;
  height: calc(100% - 80px - 32px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftBar__flex h1 {
  font-weight: 700;
}

.leftBar__flex h3 {
  margin-bottom: 0.2em;
  font-weight: 500;
  font-size: 16px;
}

.leftBar nav {
  /* margin: 80px 0 0 0; */
}

.leftBar nav ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0;
}

.leftBar nav ul li {
  color: #2F2F36;
  display: block;
  position: relative;
  font-size: 16px;
  padding-left: calc(24px + .5em);
  cursor: pointer;
  transition: all .2s ease;
}

.leftBar nav ul li:hover {
  opacity: .8;
}

.leftBar nav ul li.current {
  color: #54549E;
  font-weight: 700;
}

.leftBar nav ul li svg,
.leftBar nav ul li i {
  position: absolute;
  left: 0;
  font-size: 18px;
  max-width: 18px;
}

.leftBar__logoutBtn {
  border: none;
  background: none;
  text-transform: uppercase;
  text-align: left;
  color: #2F2F36;
  position: relative;
  font-size: 16px;
  padding-left: calc(24px + .5em);
  cursor: pointer;
  transition: all .2s ease;
}

.leftBar__logoutBtn i {
  position: absolute;
  left: 0;
  font-size: 18px;
  max-width: 18px;
}

.leftBar__logoutBtn:hover {
  opacity: .8;
}

.leftBar__menu {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 24px;
  align-items: flex-start;
  padding-right: 32px;
  margin-top: 24px;
  font-size: 12px;
}

.leftBar__menu__block input[type="radio"] {
  display: none;
}

.leftBar__menu__block input[type="radio"] + label {
  display: inline-block;
  position: relative;
  border-radius: 10px;
  padding-left: 22px;
  margin-right: 8px;
  opacity: .3;
  height: 20px;
}

.leftBar__menu__block input[type="radio"]+label svg {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
}

.leftBar__menu__block input[type="radio"]:checked + label {
  opacity: 1;
}

.leftBar__menu__block span {
  display: inline-block;
  opacity: .5;
  margin-bottom: 8px;
}

.leftBar__addSkill {
  padding-right: 32px;
  margin-top: 10px;
}

.leftBar__addSkill_btn {
  display: block;
  width: 100%;
  background: #d9d9d9;
  color: #fff;
  border: 1px #d9d9d9 solid;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  padding: 8px 12px 7px 12px;
  cursor: pointer;
  transition: all .2s ease;
}

.leftBar__addSkill_btn:hover {
  opacity: .8;
}

.leftBar__addSkill__form {
  display: none;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
}

.leftBar__addSkill.opened .leftBar__addSkill_btn {
  display: none;
}

.leftBar__addSkill.opened .leftBar__addSkill__form {
  display: grid;
}

.leftBar__addSkill__form select {
  width: 100%;
  border: 1px #221726 solid;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  padding: 8px 12px 7px 12px;
}

.leftBar__addSkill__form button {
  width: 38px;
  height: 38px;
  border: none;
  background: #f7b032;
  border-radius: 10px;
  cursor: pointer;
  transition: all .2s ease;
}

.leftBar__addSkill__form button:hover {
  background: #f4b352;
}



.leftBar__skillsList,
.leftBar__coursesList {
  display: block;
  position: relative;
  height: 100%;
  height: -webkit-fill-available;
  height: 70vh;
  overflow: auto;
  overflow-y: scroll;
  margin-top: 12px;
  padding-right: 32px;
  transition: all .2s ease-in-out;
  margin-left: -32px;
  padding-left: 32px;
}

.skillBlock {
  display: block;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  padding: 8px 48px 7px 12px;
  margin-bottom: 16px;
  overflow: visible;
}

.skillBlock__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.skillBlock__title .count {
  font-size: 12px;
  font-weight: 300;
  color: #676767;
}

.skillBlock.hidden {
  display: none;
}

.skillBlock.notContained {
  display: none !important;
}

.skillBlock.green {
  background-color: #BEE7B0;
  border: 1px solid #BEE7B0;
}

.skillBlock.green.dragOver {
  border: 1px rgba(0, 0, 0, .5) dashed;
}

.skillBlock.dragOver {
  border: 1px #6B6EB2 dashed;
}

.skillBlock__info {
  display: none;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
  row-gap: 0.3em;
  font-size: 12px;
  margin-top: 1em;
  font-style: italic;
}

.skillBlock__info div:nth-child(2n - 1) span {
  color: #676767;
  font-weight: 500;
}

.skillBlock__info div:nth-child(2n) span {
  color: #453a8f;
  word-break: break-word;
}

.skillBlock.opened .skillBlock__info {
  display: grid;
}

.skillBlock__checkbox {
  position: absolute;
  top: calc(50% - 8px);
  right: -24px;
  width: 16px;
  height: 16px;
  /* visibility: hidden; */
  opacity: 0;
  transition: all .1s ease-in-out;
}

.skillAlert {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  line-height: 36px;
  left: -27px;
}

.skillAlert__icon {
  display: inline-block;
  text-align: center;
  background-color: #ff6666;
  color: #fff;
  font-size: 20px;
  line-height: 1.2em;
  width: 1em;
  height: 1.2em;
  border-radius: 40%;
  cursor: pointer;
}

.skillAlert__block {
  display: block;
  position: absolute;
  border: 1px solid #DBDBDB;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 12px 7px 12px;
  z-index: 9;
  width: 309px;
  overflow: hidden;
  height: auto;
  left: -330px;
  top: -1px;
  line-height: 1em;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.0);
  transform: scale(0);
  transition: left .2s ease-in-out, box-shadow .2s .2s ease, transform 0s .2s;
}


.skillAlert.opened .skillAlert__block {
  transform: scale(1);
  left: 26px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  transition: left .2s ease-in-out, box-shadow .2s .2s ease, height 0s .2s;
}

.skillAlert__block h4 {
  margin: 0.5em 0 1em 0;
}

.skillAlert__identificator {
  display: inline-block;
  font-size: .8em;
  color: #DBDBDB;
}

.skillAlert__identificator:first-child {
  margin-right: 1em;
}

.skillAlert__close {
  display: block;
  position: absolute;
  border: none;
  background: none;
  color: #3f3f3f;
  width: 1em;
  height: 1em;
  right: 12px;
  top: 8px;
  cursor: pointer;
  transition: color .2s;
}

.skillAlert__close:hover {
  color: #000;
}

.skillAlert__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.skillAlert__form input[type="radio"],
.skillAlert__form input[type="checkbox"] {
  display: none;
}

.skillAlert__form input[type="radio"] + label,
.skillAlert__form input[type="checkbox"] + label {
  display: inline-block;
  border-radius: 10px;
  padding: 8px 12px 7px 12px;
  border: 1px solid #DBDBDB;
  cursor: pointer;
}

.skillAlert__form input[type="radio"]:checked + label,
.skillAlert__form input[type="checkbox"]:checked + label {
  background-color: #D3D4F2;
  border: 1px solid #bfc0ea;
}

.skillAlert__form button {
  display: block;
  width: 100%;
  border-radius: 10px;
  padding: 10px 12px 9px 12px;
  margin-top: 1em;
  border: 1px #ffae00 solid;
  background-color: #ffae00;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.skillBlock__checkbox input[type="checkbox"] {
  display: none;
}

.skillBlock__checkbox input[type="checkbox"] + label {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.skillBlock:hover .skillBlock__checkbox,
.skillBlock__checkbox:hover {
  /* visibility: visible; */
  opacity: 1;
}

.skillBlock__checkbox input[type="checkbox"] + label svg {
  line-height: 16px;
  width: 100%;
  height: 100%;
  color: #332134;
}

.leftBar .elemTypeBtn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 36px;
  height: 100%;
  background-color: #D9D9D9;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 8px;
  cursor: pointer;
  z-index: 2;
  transition: all .2s ease-in-out;
}

.leftBar .elemTypeBtn.animated {
  animation: typeBtnClick .2s ease-in-out;
}

.skillBlock.green .elemTypeBtn {
  background-color: #ABD19E;
}

@keyframes typeBtnClick {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
  }
  10% {
    transform: scale(1.0);
  }
} 

.leftBar__coursesList input[type="radio"] {
  display: none;
}

.leftBar__coursesList input[type="radio"] + label {
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  padding: 8px 12px 7px 12px;
  margin-bottom: 16px;
  overflow: visible;
  cursor: pointer;
}

.leftBar__coursesList input[type="radio"]:checked + label {
  border: 1px solid #888888;
  background-color: #DBDBDB;
}

.workZone {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  padding: 32px 0 0 32px;
  height: 100vh;
  padding-bottom: 64px;
  align-items: flex-start;
  overflow: auto;
}

.workZone.trash,
.workZone.specialisations {
  grid-template-rows: 1fr;
}

.workZone.trash .workZone__nav,
.workZone.trash .workZone__body,
.workZone.trash .workZone__specialisations,
.workZone.specialisations .workZone__nav,
.workZone.specialisations .workZone__body,
.workZone.specialisations .workZone__trash,
.workZone.main .workZone__trash,
.workZone.main .workZone__specialisations {
  display: none;
}

.workZone__nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  padding-bottom: 16px;
}

.workZone__nav input[type="radio"] {
  display: none;
}

.workZone__nav__tab {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  background-color: #D3D4F2;
  border-radius: 20px;
  padding: 8px 12px 7px 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.workZone__nav__tab:hover {
  background-color: #bfc0ea;
}

.workZone__nav__tab.btn {
  background-color: #C5E0F9;
  border: none;
}

.workZone__nav__tab.btn:hover {
  background-color: #b2d6f7;
}

.workZone__nav input[type="radio"]:checked + label.workZone__nav__tab:not(.btn) {
  background-color: #898CDB;
}

.workZone__nav__tab[data-value="soft"],
.workZone__nav__tab[data-value="trait"] {
  background-color: #f5c986;
}

.workZone__nav__tab[data-value="soft"]:hover,
.workZone__nav__tab[data-value="trait"]:hover {
  background-color: #f3bf71;
}

.workZone__nav input[type="radio"][value="soft"]:checked + label.workZone__nav__tab:not(.btn),
.workZone__nav input[type="radio"][value="trait"]:checked + label.workZone__nav__tab:not(.btn) {
  background-color: #f4b352;
}

.workZone__body {
  max-height: calc(100vh - (50px + 32px + 32px));
  overflow: auto;
  padding-bottom: 64px;
  padding-right: 32px;
  transition: all .3s ease-in-out;
}

.workZone__col {
  display: flex;
  flex-direction: column;
  width: max-content;
  row-gap: 8px;
  column-gap: 8px;
  align-items: flex-start;
  text-align: center;
}

.workZone__row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  row-gap: 8px;
  column-gap: 8px;
  align-items: flex-start;
  text-align: center;
}

.workZone__col {
  grid-template-columns: 1fr;
}

.workZone__row {
  grid-template-columns: repeat(var(--columns), auto) auto;
}

.workZone__flex {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

/* .workZone__flex .workZone__elem:not(.empty):nth-child(3n + 1) {
  background-color: #6B6EB2;
}

.workZone__flex .workZone__elem:not(.empty):nth-child(3n + 2) {
  background-color: #9498E5;
}

.workZone__flex .workZone__elem:not(.empty):nth-child(3n + 3) {
  background-color: #B8BBFB;
} */

.workZone__elem {
  display: block;
  width: 100%;
  min-width: max-content;
  position: relative;
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 20px;
  padding: 20px 20px 26px 20px;
  font-size: 20px;
  font-weight: 700;
  min-height: 70px;
  --fontsize: 16px;
  /* min-width: 100px; */
  max-height: 75px;
  transition: all .3s ease-in-out;
}

.workZone__flex > .workZone__elem {
  width: auto;
}

.workZone__elem {
  padding: 20px 60px 26px 60px;
}

.workZone__col .workZone__col .workZone__elem {
  padding: 20px 40px 26px 40px;
}

.workZone__col .workZone__col .workZone__col .workZone__elem {
  padding: 20px 10px 26px 10px;
}



.workZone__elem.empty {
  color: #000000;
  text-align: center;
  padding: 19px 16px 25px 19px !important;
  border: 1px #ffffff solid;
  transition: border .2s ease-in-out;
  overflow: hidden;
}

.workZone__elem.empty:after {
  display: inline;
  position: relative;
  content: "put here";
  opacity: .3;
  font-size: 12px;
  font-weight: 400;
}

.workZone__elem.empty.dragOver {
  border: 1px #6B6EB2 dashed;
}

.workZone__col:first-child > .workZone__elem:not(.empty) {
  background-color: #6B6EB2;
}

.workZone__col:first-child .workZone__col .workZone__elem:not(.empty) {
  background-color: #9498E5;
}

.workZone__col:first-child .workZone__col .workZone__col .workZone__elem:not(.empty) {
  background-color: #B8BBFB;
}

/* .workZone__row > .workZone__col + .workZone__elem.empty {
  display: none;
}

.workZone__row:hover > .workZone__col + .workZone__elem.empty {
  display: block;
} */

.workZone__elem.duplicated {
  background-color: #f5c986 !important;
  box-shadow: 0 0 10px -1px #8a8cdb;
}

.workZone__elem.opacited {
  opacity: .2 !important;
}

.workZone__elem__name {
  display: inline-block;
  font-size: 16px;
  font-size: var(--fontsize);
}

.workZone__elem__tooltip {
  display: block;
  position: absolute;
  margin: 0 auto;
  transform: rotate(-15deg);
  top: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 8px;
  background: rgba(107, 110, 178);
  border-radius: 12px;
  left: 100%;
  width: max-content;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;
}

.workZone__elem__tooltip::before {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  left: -8px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 9px solid rgba(107, 110, 178);
}

.workZone__elem:hover .workZone__elem__tooltip {
  top: calc(50% - 12px);
  transform: rotate(0);
  opacity: 1;
  visibility: visible;
}

.dragging {
  /* position: fixed; */
  z-index: 99;
}

.dragging .workZone__elem__tooltip {
  display: none;
}

.synonymsBlock {
  display: inline-block;
  position: relative;
}

.workZone__elem .synonymsBlock {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: inherit;
  max-width: calc(100% - 70px);
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}

.skillBlock__info .synonymsBlock {
  background-color: #fff;;
}

.synonymsBlock > span {
  cursor: context-menu;
  max-height: 1em;
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: context-menu;
}

.synonymsEdit {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  text-align: left;
  column-gap: 8px;
  align-items: flex-start;
  position: absolute;
  background-color: inherit;
  top: 20px;
  left: calc(50% - 75px);
  margin: 0 auto;
  padding: 8px;
  z-index: 4;
  width: 150px;
  border-radius: 8px;
  opacity: 0;
  line-height: 19px;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.synonymsEdit span {
  font-size: 12px;
}

.synonymsEdit .removeBtn {
  padding: 0;
  border: none;
  background: none;
  color: #221726;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.synonymsEdit .removeBtn:hover {
  opacity: .7;
}

.synonymsBlock.opened .synonymsEdit {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.workZone .elemTypeBtn {
  display: inline-block;
  position: relative;
  width: 21px;
  height: 21px;
  background-color: #D9D9D9;
  vertical-align: middle;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 8px;
  padding: 0;
  line-height: 21px;
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0.6em;
  z-index: 3;
  transition: all .2s ease-in-out;
}


.workZone__viewTabs {
  display: inline-block;
  position: fixed;
  bottom: 0;
  left: 382px;
  z-index: 100;
}

.workZone__viewTabs input[type="radio"] {
  display: none;
}

.workZone__viewTabs input[type="radio"] + label {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  background-color: #322134;
  color: #4f3e4e;
  padding: 12px 32px 11px 32px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.workZone__viewTabs input[type="radio"] + label:first-of-type {
  border-radius: 20px 0 0 0;
}

.workZone__viewTabs input[type="radio"] + label:last-of-type {
  border-radius: 0 20px 0 0;
}

.workZone__viewTabs input[type="radio"]:checked + label {
  padding: 13px 32px 12px 32px;
  background-color: #241426;
  color: #fff;
}

.workZone__viewTabs input[type="radio"] + label:hover {
  padding: 13px 32px 12px 32px;
}

.workZone__viewTabs input[type="radio"] + label:first-of-type:hover,
.workZone__viewTabs input[type="radio"]:checked + label:first-of-type {
  border-radius: 20px 4px 0 0;
}

.workZone__viewTabs input[type="radio"] + label:last-of-type:hover,
.workZone__viewTabs input[type="radio"]:checked + label:last-of-type {
  border-radius: 4px 20px 0 0;
}

.workZone__trash {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
  padding-right: 32px;
}

.workZone__trash__container {
  background: #7066b4;
  border-radius: 20px;
}

.workZone__trash h3 {
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0;
}

.workZone__trash__block {
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  border: 1px #fff solid;
  height: calc(100vh - (32px + 24px + 32px + 64px));
  padding: 15px;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.workZone__trash__block.dragOver {
  border: 1px #000 dashed;
}

.workZone__trash__box {
  padding-right: 16px;
  overflow: auto;
  height: 100%;
}

.workZone__trash__box .skillBlock {
  display: inline-block;
  margin: 0 8px 8px 0;
  padding: 8px 12px 7px 12px;
  cursor: grab;
}

.workZone__specialisations {
  padding-right: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
}

.workZone__specialisations__block {
  position: relative;
  height: calc(100vh - (32px + 24px + 32px + 64px));
}

.workZone__specialisations h3 {
  font-size: 20px;
  min-height: 2em;
}

.workZone__specialisations h3 span {
  display: inline-block;
  background: rgba(255,255,255,.7);
  text-transform: uppercase;
  border-radius: .5em;
  padding: .2em .4em;
}

.workZone__specialisations__box {
  display: block;
  border-radius: 20px;
  border: 2px #000 dashed;
  padding: 15px;
  min-height: 200px;
  height: 100%;
}

.workZone__specialisations .skillBlock {
  display: inline-block;
  margin: 0 8px 8px 0;
  padding: 8px 12px 7px 12px;
  cursor: grab;
  background-color: #fff;
}

.parentTypeBtn {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  top: calc(50% - 20px);
  border-radius: 50%;
  padding: 0;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;
}

.parentTypeBtn.general {
  background-color: #333333;
  color: #fff;
}

.parentTypeBtn.class {
  background-color: #fff;
  color: #333333;
  opacity: 1;
  visibility: visible;
}

.workZone__elem:hover .parentTypeBtn {
  opacity: 1;
  visibility: visible;
}

.parentTypeBtn svg {
  line-height: 40px;
  font-size: 16px;
}

.workZone__col .workZone__col .parentTypeBtn {
  width: 30px;
  height: 30px;
  line-height: 30px;
  right: 15px;
  top: calc(50% - 15px);
}

.workZone__col .workZone__col .parentTypeBtn svg {
  font-size: 14px;
  line-height: 30px;
}

.workZone__col .workZone__col .workZone__col .parentTypeBtn {
  width: 20px;
  height: 20px;
  line-height: 10px;
  right: 10px;
  top: 10px;
}

.workZone__col .workZone__col .workZone__col .parentTypeBtn svg {
  font-size: 10px;
  line-height: 20px;
}

.removeSkillBtn {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: rgba(0, 0, 0, .5);
  border:none;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.workZone__col .workZone__col .removeSkillBtn {
  bottom: 10px;
  left: 10px;
}

.workZone__col .workZone__col .workZone__col .removeSkillBtn {
  bottom: 4px;
  left: 4px;
}

.removeSkillBtn:hover {
  color: rgba(0, 0, 0, .7);
}

.workZone__elem:hover .removeSkillBtn {
  display: inline-block;
}

.innerInput {
  border: none;
  outline: none;
  color: rgba(0, 0, 0, .7);
  font-size: 16px;
  font-size: var(--fontsize);
  font-style: italic;
  transition: all .2s;
}

.workZone__elem.empty .innerInput,
.workZone__elem.empty.input::after {
  display: none;
  position: absolute;
  z-index: -1;
  bottom: -100%;
}

.workZone__elem.empty.input .innerInput {
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 1;
  bottom: unset;
}

.workZone__elem__topBtn {
  display: block;
  position: absolute;
  border: none;
  background-color: #898CDB;
  color: #fff;
  height: 30px;
  width: 60px;
  border-radius: 60px 60px 0 0;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 16px;
  line-height: 30px;
  padding-top: 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.workZone__body > .workZone__col:first-of-type > .workZone__elem:not(.empty) {
  margin-top: 30px;
}

.modal {
  display: block;
  position: fixed;
  z-index: 100;
  top: 30vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: auto;
  max-width: 540px;
  padding: 42px 32px 32px 32px;
  border-radius: 20px;
  background-color: #221726;
  color: #fff;
  text-align: center;
}

.modal__close {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 0;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border: none;
  background: none;
  top: 10px;
  right: 10px;
  cursor: pointer;
  opacity: .5;
  transition: all .2s ease-in-out;
}

.modal__close:hover {
  opacity: .9;
}

.modal__buttons {
  margin-top: 32px
}

.modal__buttons button {
  display: inline-block;
  border: none;
  border-radius: 16px;
  padding: 12px 16px 11px 16px;
  background-color: #7066b4;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  margin-right: 10px;
  transition: all .2s ease-in-out;
}

.modal__buttons button:nth-child(2) {
  background-color: #9990e7;
}

.modal__buttons button.cancel {
  background-color: #ffae00;
}

.modal__buttons button:last-child {
  margin-right: 0;
}

.modal__buttons button:hover {
  opacity: .7;
}

.courseEdit {
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
  row-gap: 16px;
  padding: 32px 32px 64px 32px;
  height: 100vh;
  align-items: flex-start;
  overflow: auto;
}

.courseEdit__string {
  display: inline-block;
  position: relative;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, .5);
}

.courseEdit__string.edit,
.courseEdit__number.edit {
  background-color: rgba(255, 255, 255, 0);
}

.courseEdit__editBtn {
  border: none;
  background: none;
  color: #7066b4;
  display: inline-block;
  margin-right: .5em;
  outline: none;
}

.courseEdit__string input[type="text"],
.courseEdit__number input[type="text"] {
  display: block;
  position: absolute;
  width: 100%;
  border: none;
  padding: inherit;
  border-radius: inherit;
  background: inherit;
  left: 0;
  top: 0;
  font-size: inherit;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
}

.courseEdit__string.edit input[type="text"],
.courseEdit__number.edit input[type="text"] {
  z-index: 2;
  opacity: 1;
  visibility: visible;
}

.courseEdit__string.edit span,
.courseEdit__string.edit a,
.courseEdit__string.edit .courseEdit__editBtn,
.courseEdit__number.edit span {
  z-index: 0;
  opacity: 0;
  visibility: hidden;
}

.courseEdit__number {
  display: inline-block;
  position: relative;
  padding: 8px;
  border-radius: 8px;
  letter-spacing: 2px;
  font-weight: 500;
  color: #940a8c;
  background: rgba(255, 255, 255, .2);
}

.courseEdit__number input[type="text"] {
  letter-spacing: inherit;
  font-weight: inherit;
}

.courseEdit__number .durationStr {
  position: absolute;
  left: calc(100% + 0.5em);
  top: calc(50% - 0.5em);
  width: max-content;
  font-weight: 300;
  color: #000;
  letter-spacing: normal;
  font-size: 12px;
  line-height: 1em;
}

.courseEdit__bigText {
  display: block;
  position: relative;
  border: 2px #7066b4 solid;
  padding: 8px;
  border-radius: 8px;
  line-height: 1.5em;
  
}

.courseEdit__bigText div * {
  font-size: 16px;
}

.courseEdit__bigText:before {
  display: block;
  position: relative;
  color: #7066b4;
  font-weight: 600;
  margin-bottom: .5em;
  content: "</>";
}

.courseEdit__bigText.edit:before {
  display: none;
}

.courseEdit__saveBtn {
  display: block;
  position: relative;
  color: #488f32;
  margin-bottom: .5em;
  padding: 8px 12px;
  border-radius: 16px;
  border: 2px #abd19e solid;
  background: none;
  cursor: pointer;
  transition: all .2s ease;
}

.courseEdit__saveBtn:hover {
  background: #abd19e;
}

.courseEdit__saveBtn svg {
  font-size: 20px;
  vertical-align: middle;
}

.courseEdit__saveBtn span {
  font-size: 16px;
  margin-left: .3em;
  vertical-align: middle;
}

.courseEdit__boolean {
  display: inline-block;
  border-radius: 16px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.courseEdit__boolean.true {
  background-color: #ABD19E;
  color: #488e32;
}

.courseEdit__boolean.false {
  background-color: #d19e9e;
  color: #9b3131;
}

.courseEdit__boolean.animated {
  animation: typeBtnClick .2s ease-in-out;
}

.courseEdit__dictionary {
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
  row-gap: 8px;
  align-items: flex-start;
  background: rgba(183, 177, 218, .5);
  border-radius: 16px;
  padding: 16px;
}

.courseEdit__list {
  border-radius: 16px;
  border: 2px #7066b4 dashed;
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
}

.courseEdit__list > .courseEdit__string {
  border-radius: 16px;
  padding: 8px 16px;
  background: #8973AF;
}

.courseEdit__list > .courseEdit__string:nth-child(5n + 2) {
  background: #F2A585;
}

.courseEdit__list > .courseEdit__string:nth-child(5n + 3) {
  background: #C37BAC;
}

.courseEdit__list > .courseEdit__string:nth-child(5n + 4) {
  background: #F7CC78;
}

.courseEdit__list > .courseEdit__string:nth-child(5n + 5) {
  background: #EF8B9B;
}

.courseEdit__key {
  position: relative;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  padding: 8px 0;
}

.courseEdit__value {
  display: block;
  position: relative;
  width: 100%;
  word-break: break-word;
}

.courseEdit__imgBlock {
  display: block;
  width: auto;
  max-width: 40vw;
  margin-top: 32px;
} 

.courseEdit__imgBlock__title {
  font-size: 16px;
  font-weight: 700;
  color: #322e50;
}

.courseEdit__imgBlock img {
  max-width: 100%;
}

.fileInput {
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto;
  column-gap: 16px;
  margin-top: 16px;
}

.fileInput input {
  display: none;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.fileInput input + label {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  padding: 10px 32px 9px 32px;
  border: 2px #7066b4 dashed;
  color: #322e50;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  transition: border .2s ease-out;
}

.fileInput input + label span,
.fileInput input + label p {
  z-index: 1;
}

.fileInput__name {
  position: relative;
  width: 100%;
  text-align: center;
  color: #555;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  text-transform: none;
  margin: 0;
  font-weight: 400;
}

.fileInput input:hover + label,
.fileInput input:focus + label {
  border: 2px #322e50 dashed;
}

.fileInput__btn {
  display: inline-block;
  border-radius: 16px;
  padding: 10px 20px 9px 20px;
  border: 2px #7066b4 dashed;
  background-color: #7066b4;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.fileInput__btn:disabled {
  opacity: .5;
  cursor: default;
}

.fileInput__progress {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #f7b032;
  z-index: 0;
  transition: all .2s ease;
}

.courseAlert__icon {
  display: inline-block;
  text-align: center;
  background-color: #ff6666;
  color: #fff;
  font-size: 20px;
  line-height: 1.2em;
  width: 1em;
  height: 1.2em;
  border-radius: 40%;
  position: absolute;
  left: -1.2em;
  top: calc(50% - 0.6em);
}

.courseBtns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: fixed;
  bottom: 0;
  right: 32px;
  left: 382px;
  height: 48px;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
  z-index: 5;
}

.courseBtns__green,
.courseBtns__red {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.courseBtns__green .courseAlert__icon,
.courseBtns__red .courseAlert__icon {
  position: relative;
  top: unset;
  left: unset;
  margin-right: 0.2em;
}

.courseBtns__green {
  background-color: #ABD19E;
  color: #488e32;
}

.courseBtns__green:hover {
  background-color: #75d056;
}

.courseBtns__red {
  background-color: #d19e9e;
  color: #9b3131;
}

.courseBtns__red:hover {
  background-color: #ce6161;
}

.courseEdit__expand {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}

.courseEdit__btnBlock {
  padding: 6px 0;
}

.expandBtn {
  position: relative;
  background: #8973af;
  outline: none;
  cursor: pointer;
  border: 2px #8973af solid;
  color: #30283d;
  border-radius: 8px;
  font-size: 16px;
}

.expandBtn svg {
  transition: all .2s ease;
}

.expandBtn::after {
  display: inline-block;
  position: absolute;
  left: calc(100% + 0.5em);
  width: max-content;
  transition: all .2s ease-in-out;
  opacity: 0;
  z-index: 5;
}

.expandBtn:hover::after {
  opacity: 1;
}

.courseEdit__expand > .courseEdit__btnBlock > .expandBtn::after {
  content: "expand";
}

.courseEdit__expand.opened > .courseEdit__btnBlock > .expandBtn::after {
  content: "collapse";
}

.courseEdit__expand.opened > .courseEdit__btnBlock > .expandBtn svg {
  transform: rotate(-180deg);
}


.courseEdit__expand:not(.opened) > div:not(.courseEdit__btnBlock) {
  display: none;
}

.mainZone {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
}

.mainZone__header,
.mainZone__block {
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
}

.mainZone__block h3 {
  text-transform: capitalize;
  margin: 0 0 1.5em 0;
}

.mainZone__block h3 i {
  vertical-align: middle;
  line-height: 1em;
  margin-left: .5em;
}

.mainZone__block h3 i.blue {
  color: #54549E;
}

.mainZone__block h3 i.yellow {
  color: #ffae00;
}

.mainZone__block h3 i.green {
  color: #7cbe65;
}

.mainZone__flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 24px;
}

.mainZone__infoBlock {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  padding: 16px 18px;
  border: 1px #000 dashed;
  font-size: 16px;
}


.mainZone__infoBlock__icon i {
  font-size: 20px;
  display: inline-block;
  line-height: 1em;
  height: 1em;
  vertical-align: middle;
}

.mainZone__infoBtn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  padding: 16px 18px;
  color: #fff;
  background-color: #201926;
  border: 1px #201926 solid;
  font-size: 16px;
  cursor: pointer;
  transition: all .2s ease;
}

.mainZone__infoBtn:hover {
  border: 1px #f7b032 solid;
}

.mainZone__infoBtn i {
  color: #f7b032;
  font-size: 20px;
  line-height: 1em;
  height: 1em;
}

.professionForm {
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.professionForm select {
  margin: 0;
  border: 1px #221726 solid;
  padding: 8px 12px 8px 12px;
}