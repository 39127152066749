@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  background: #E5E5E5;
  color: #000;
  margin: 0;
}

form {
  margin-top: 32px;
}

p {
  margin-top: 0;
  font-size: 14px;
}